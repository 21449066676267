import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import React, { useEffect } from 'react';
const oas = require("./openapi.yaml");

function App() {
  useEffect(() => {
    document.title = 'DC Partner API Docs';
  }, []);

  return (
    <div className="App">
      <SwaggerUI url={oas}
      requestInterceptor={(request) => {
        request.headers['waf-key'] = 'XtRZq3mlYdHCk9JQWpp';
        return request;
      }}
      />
    </div>
  );
}

export default App;
